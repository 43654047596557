<template>
  <div class="container">
    <header class="header">
      <div class="header-content">
        <span class="app-title">魔法秘境-英语APP</span>
        <span class="text_close" v-show="!isShowLogin" @click="closeLogin()">[退出账号]</span>
        <div class="user-info" v-show="!isShowLogin">
          <p class="nickname">昵称：{{ nickName }}</p>
          <p class="phone">手机号：{{ phone }}</p>
        </div>
        <span class="login-text" v-show="isShowLogin" @click="login()">未登录，点击登录</span>
      </div>
    </header>

    <main class="content">
      <div class="amount-section" v-for="(item, index) in data" :key="index" :class="{ selected: selectedItem === item.id, disabled: item.IsPay }" @click="!item.IsPay && selectItem(item)">
        <div class="option">
          <img class="option-img" :src="getImageSrc(item.imgurl)" />
          <div class="text-group">
            <span class="item-name">{{ item.name }}</span>
            <span class="item-description">{{ item.description }}</span>
          </div>
        </div>
        <div class="price">
          <span v-if="!item.IsPay">
            <span class="currency">¥</span>
            <span class="amount">{{ item.price }}</span>
          </span>
          <span v-else class="text-payed">已购买</span>
        </div>
      </div>
    </main>

    <footer class="footer">
      <div class="support">
        如您遇到充值问题，请拨打客户电话<br />
        <span class="support-phone">18917565986</span>
      </div>

     <div class="drawer" :class="{ 'drawer-visible': isshowapy }">
      <div class="drawer-content">
        <div >
          
          <span class="amount">{{ selectamount }} <span class="currency">¥</span></span>
          <span class="payment-text">实付金额</span>
        </div>
        <div class="pay-button" @click="goPay()">
          <span class="pay-text">去支付</span>
        </div>
      </div>
    </div>


    </footer>

    <div v-if="showModal" class="modal-overlay"></div>
    <div v-if="showModal" class="modal-content">
      <component :is="modalComponent" @login-success="closeModal()"></component>
    </div>
  </div>
</template>

<script>
import LoginPage from './Login.vue';
import PayPage from './PaySuccess.vue';

export default {
  name: 'HomePage',
  components: {
    LoginPage,
    PayPage
  },
  data() {
    return {
      data: [],
      selectedItem: null,
      isShowLogin: true,
      showModal: false,
      modalComponent: '',
      nickName: '',
      phone: '',
      selectamount: 0,
      isshowapy: false,
      paidPackageId: '',
      iteminfo: {},
    };
  },
  created() {
    // 保存URL中的cooperateCode到localStorage
    const cooperateCode = this.$route.query.cooperateCode;
    if (cooperateCode) {
      localStorage.setItem('cooperateCode', cooperateCode);
    }
    
    const outTradeNo = this.$route.query.out_trade_no;
    if (outTradeNo) {
      localStorage.setItem('outTradeNo', outTradeNo);
      this.modalComponent = 'PayPage';
      this.showModal = true;
    }
  },
    mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get('pid')){
      this.paidPackageId = urlParams.get('pid');
      // 如果有pid参数，直接显示支付抽屉
    }
    const token = localStorage.getItem('token');
    if (token) {
      this.isShowLogin = false;
      this.nickName = localStorage.getItem('nickName');
      this.phone = localStorage.getItem('phone');
    }
    this.getPackage().then(() => {
      if(this.paidPackageId && this.data.length > 0) {
        // 设置第一个套餐为默认选择
        this.selectItem(this.data[0]);
      }
    });
  },
  methods: {
    async getPackage() {
      console.log(this.paidPackageId)
      const cooperateCode = localStorage.getItem('cooperateCode') || '';
      this.$http.post('/PaidPackage/GetPaidPackagesList', { 
        phone: this.phone,
        cooperateCode: cooperateCode,
        paidpackageId: this.paidPackageId
      }).then(response => {
        this.data = response.data.Data;

        const urlParams = new URLSearchParams(window.location.search);
        const token = localStorage.getItem('token');
        if(urlParams.get('pid') && token){
          this.paidPackageId = urlParams.get('pid');
          this.selectItem(this.data[0])
          // 如果有pid参数，直接显示支付抽屉
          this.isshowapy = true;
        }

      });
    },
    getImageSrc(imgName) {
      if(this.paidPackageId){
        return require(`@/img/option1.png`);
      }
      return require(`@/img/${imgName}`);
    },
    selectItem(item) {
      const token = localStorage.getItem('token');
      if (token) {
        if (item) {
          this.iteminfo = item;
          this.selectedItem = item.id;
          this.selectamount = item.price;
          this.isshowapy = true;
        } else {
          this.isshowapy = false;
          this.selectamount = 0;
        }
      } else {
        this.modalComponent = 'LoginPage';
        this.showModal = true;
      }
    },
    closeModal() {
      const token = localStorage.getItem('token');
      if (token) {
        this.isShowLogin = false;
        this.showModal = false;
        this.nickName = localStorage.getItem('nickName');
        this.phone = localStorage.getItem('phone');
      } else {
        this.isShowLogin = true;
        this.showModal = false;
      }
      this.$router.push({ name: 'HomePage', query: { pid: this.paidPackageId } });
    },
    login() {
      this.modalComponent = 'LoginPage';
      this.isShowLogin = true;
      this.showModal = true;
    },
    closeLogin() {
      localStorage.removeItem('token');
      localStorage.removeItem('nickName');
      localStorage.removeItem('phone');
      this.login();
    },
    showDrawer(){
      this.isshowapy = true; // 显示抽屉
    },
    hideDrawer(){
      this.isshowapy = false; // 隐藏抽屉
    },
    goPay() {
      if (this.selectedItem) {
        this.$router.push({ name: 'MfDetailInfo', query: { iteminfo: JSON.stringify(this.iteminfo) } });
      } else {
        alert('请选择套餐');
      }
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 2vw;
  background-image: url('~@/img/bjt.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.header {
  flex: 0 0 22vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url('~@/img/header.png');
  background-size: cover;
  background-position: center;
  padding: 1vw;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.app-title {
  font-size: 5vw;
  color: rgba(255, 223, 0, 1);
}

.text_close {
  cursor: pointer;
  color: white;
  font-size: 1.5vw;
}

.user-info {
  color: white;
  font-size: 3vw;
}

.login-text {
  color: white;
  font-size: 3vw;
  text-decoration: underline;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 1vw;
}

.amount-section {
  display: flex;
  justify-content: space-between;
  padding: 2vw;
  margin: 1vw 0;
  border-radius: 5px;
  background-image: url('~@/img/optionbj.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 15vw;
  position: relative;
}
.option {
  position: relative; /* 创建定位上下文，为绝对定位提供参考 */
  width: 100%; /* 父容器宽度 */
  height: auto; /* 根据内容自动调整高度 */
  overflow: hidden; /* 隐藏溢出的内容 */
}

.option-img {
  width: 100%; /* 图片宽度占满父容器 */
  height: auto; /* 保持宽高比 */
  display: block; /* 防止默认 inline 元素的间隙 */
}

.text-group {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.0);
  color: #fff;
  padding: 1rem;
  text-align: center;
  transform: translateY(-10%); /* 内容整体向上移动10% */
}


.item-name {
  font-weight: bold;
  font-size: 4vw;
  color: #000000; /* 纯黑色 */
  display: block;
  margin-bottom: 1vw; /* 增加与描述文字之间的间距 */
}

.item-description {
  font-size: 3vw;
  color: #4f4f4f; /* 浅黑色（稍微比纯黑浅一些） */
  display: block;
}

.price {
  display: flex;
  align-items: bottom;
  position: absolute;
  right: 3vw;
  top: 50%; /* 距离容器顶部50% */
  transform: translateY(-110%); /* 向上移动自身高度的50% */
  font-weight: bold;
  font-size: 4vw;
}


.currency {
  font-size: 2.5vw;
}

.amount {
  font-size: 5vw;
  color: red;
}

.text-payed {
  font-size: 3vw;
  color: rgba(255, 83, 83, 1);
}

.footer {
  flex: 0 0 20vh;
  text-align: center;
  padding: 1vw;
}

.support {
  color: rgba(94, 79, 128, 1);
}

.payment-info {
  display: flex;
  align-items: center;
  justify-content: space-between; /* 左右两部分对齐 */
  background-color: #fff; /* 白色背景 */
  border-radius: 2rem; /* 圆角 */
  padding: 1.5vw 3vw; /* 内边距 */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* 添加轻微阴影 */
}

.amount-section {
  display: flex;
  align-items: baseline; /* 对齐基线 */
  gap: 0.5vw; /* 每个子元素的间距 */
}


.pay-button {
  background-color: #FF5F5F;
  border-radius: 2rem; /* 圆角按钮 */
  padding: 1vw 3vw; /* 按钮内边距 */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}


.pay-text {
  color: white;
  font-size: 3.5vw;
  font-weight: bold;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 80%;
  max-width: 90%;
  z-index: 1000;
}


/* 抽屉的整体容器 */
.drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* 设置抽屉高度为30% */
  background-color: #ffffff; /* 抽屉背景颜色 */
  border-top-left-radius: 1.5rem; /* 圆角设计 */
  border-top-right-radius: 1.5rem;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); /* 上方阴影效果 */
  transform: translateY(100%); /* 默认隐藏在屏幕下方 */
  transition: transform 0.3s ease-in-out; /* 弹出动画 */
  z-index: 1000; /* 确保在最上层 */
}

/* 抽屉可见状态 */
.drawer-visible {
  transform: translateY(0); /* 从屏幕底部滑入 */
}

/* 抽屉内容区域 */
.drawer-content {
  padding: 2vw 4vw; /* 抽屉内容内边距 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.drawer-content > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2vw;
}

.pay-button {
  width: 80%;
  padding: 2vw;
  margin-top: 3vw;
}

/* 金额部分 */
.amount-section {
  display: flex;
  align-items: baseline;
  gap: 1vw; /* 元素之间的间距 */
}

.currency {
  font-size: 3vw;
  color: red; /* 货币符号颜色 */
}

.amount {
  font-size: 5vw;
  font-weight: bold;
  color: red;
}

.payment-text {
  font-size: 2.5vw;
  color: #888; /* 浅灰色文字 */
  margin-left: 1vw;
}

/* 支付按钮 */
.pay-button {
  background-color: #FF5F5F;
  border-radius: 2rem; /* 按钮圆角 */
  padding: 1vw 3vw; /* 按钮内边距 */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pay-text {
  color: white;
  font-size: 3.5vw;
  font-weight: bold;
}

</style>
